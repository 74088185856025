import React from 'react';
import Launch from 'views/Launch';
import Minimal from 'layouts/Minimal';
import WithLayout from 'WithLayout';

const NewpagePg = () => {
    return (
        <WithLayout
            component={Launch}
            layout={Minimal}
        />
    )
};

export default NewpagePg;